<!--
 * @Author: your name
 * @Date: 2021-11-25 14:53:38
 * @LastEditTime: 2023-02-10 15:43:22
 * @LastEditors: 韩鸿 1014315067@qq.com
 * @Description: 病害 用户收益
 * @FilePath: \new-website-1123\src\views\programme\pests\profit.vue
-->
<template>
  <div class="pests-profit">
    <p-title>
      为用户带来的
      <template v-slot:color>收益</template>
    </p-title>
    <p class="desc-p">
      使病虫害的防治由人工摸底、手工填报、经验判断的方式向大范围全方位监测、自动实时汇总分析、凭数据佐证的方式转变，实现更加智能高效解决绿色防控问题，更加省时省力省心作业。
    </p>
    <div class="img-div">
      <img src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/programme/pests/profit.png" alt="上太科技" />
    </div>
  </div>
</template>

<script>
import pTitle from "../components/title.vue";
export default {
  name: "",
  components: {
    pTitle,
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang='scss' scoped>
.pests-profit {
  padding-top: 80px;
  padding-bottom: 50px;
  .desc-p {
    margin: 50px auto;
    text-align: center;
    font-size: 22px;
    font-weight: 400;
    color: rgba($color: #fff, $alpha: 0.7);
    line-height: 36px;
    opacity: 0.7;
    width: 1200px;
  }
  @media screen and (max-width: 1400px) {
    .desc-p {
      width: 1100px;
      font-size: 18px;
    }
  }
  .img-div {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      max-width: 100%;
    }
  }
}

@media screen and (max-width: $mobile-width) {
  .pests-profit {
    padding-top: 60px;
    padding-bottom: 40px;
    .desc-p {
      margin: 10px auto;
      width: 86%;
      text-align: center;
      font-size: 0.2rem;
      line-height: 0.33rem;
      opacity: 0.7;
    }
  }
}
</style>
