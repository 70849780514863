<!--
 * @Author: your name
 * @Date: 2021-11-25 11:24:44
 * @LastEditTime: 2023-02-10 16:01:00
 * @LastEditors: 韩鸿 1014315067@qq.com
 * @Description: 解决方案 病虫害
 * @FilePath: \new-website-1123\src\views\programme\pests\index.vue
-->
<template>
  <div class="programme-pests">
    <div class="banner-box">
      <p-banner :url="banner">
        <div class="banner-content-div">
          <span class="title-span">病虫害绿色防控解决方案</span>
          <span class="desc-span"
            >上太科技智慧农业病虫害绿色防控解决方案是基于现代智能物联网技术的绿色植保防控综合性解决方案。方案</span
          >
          <span class="desc-span"
            >围绕病虫害的预测、预警、采集、分析、辅助研判、指导防治等一系列管理流程，提供基于人工智能、大数据分析的全新绿色防控手段。</span
          >
          <ul class="banner-ul">
            <li
              class="banner-li"
              v-for="item in bannerItemList"
              :key="item.key"
            >
              <span class="icon-span iconfont" :class="item.icon">
                <!-- <img :src="item.icon" alt="上太科技" /> -->
              </span>
              <span class="label-span">
                {{ item.label }}
              </span>
            </li>
          </ul>
        </div>
      </p-banner>
      <!-- <div class="filter-div"></div> -->
    </div>
    <div></div>
    <p-question class="opacity-page" :class="clsFun()"></p-question>
    <!-- 优势 -->
    <p-advantage
      class="opacity-page scale-page"
      :title="{
        text: '方案',
        color: '优势',
      }"
      :dataList="advantageList"
      v-if="advantageList.length > 0"
    >
      <template v-slot:desc>
        采用工业级人工智能能图像识别技术，更精准可靠；采用基于Strom的高流量、高并发大数据实时计算，预测更灵敏；采用兼容传统植保与现代植保Workflow，业务流程灵活可调，契合多种管控方式。
      </template>
    </p-advantage>
    <!-- 收益 -->
    <p-profit class="opacity-page scale-page"></p-profit>
    <!-- 方案架构 -->
    <p-framework
      class="opacity-page scale-page"
      :data-list="frameworkList"
      :url="frameworkUrl"
    >
      <span slot="label" class="f-label">上太智慧农业平台</span>
    </p-framework>
    <!-- 设备 -->
    <p-device
      class="opacity-page w-device"
      :title="{
        text: '关联硬件',
        color: '设备',
      }"
      :device-list="deviceList"
      :active="deviceActive"
      @change="deviceActive = $event"
    >
      <img :src="currentDevice.cover" alt="" />
    </p-device>
    <m-device class="m-device" :list="deviceList"></m-device>
  </div>
</template>

<script>
import pBanner from "../components/banner.vue";
import pQuestion from "./question.vue";
import pAdvantage from "../components/swiper.vue";
import pProfit from "./profit.vue";
import pFramework from "../components/framework.vue";
import pDevice from "../components/device.vue";
import mDevice from "../components/deviceMobile.vue";
import {getNesViewApi} from '@/api/index.js'
import {getBannerList}from '@/utils/getBanner.js'
export default {
  name: "programme-pests",
  components: {
    pBanner,
    pQuestion,
    pAdvantage,
    pProfit,
    pFramework,
    pDevice,
    mDevice,
  },
  data() {
    return {
      banner: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/programme/pests/banner.png",
      bannerItemList: [
        {
          key: 1,
          label: "预测",
          icon: "iconyuce",
        },
        {
          key: 2,
          label: "预警",
          icon: "iconyujing",
        },
        {
          key: 3,
          label: "采集",
          icon: "iconcaiji",
        },
        {
          key: 4,
          label: "分析",
          icon: "iconfenxi",
        },
        {
          key: 5,
          label: "研判",
          icon: "iconyanpan",
        },
        {
          key: 6,
          label: "防治",
          icon: "iconfangzhi",
        },
      ],
      advantageList: [
        // {
        //   key: 1,
        //   cover: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/home/programme/Solutionimg1.jpg",
        // },
        // {
        //   key: 2,
        //   cover: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/home/programme/Solutionimg2.jpg",
        // },
        // {
        //   key: 3,
        //   cover: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/home/programme/Solutionimg3.jpg",
        // },
      ],

      frameworkUrl: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/programme/pests/framework.png",
      frameworkList: [
        {
          key: 1,
          label: "智慧园区",
          desc: "客户专属VIP3D实景动态建模",
        },
        {
          key: 2,
          label: "智慧物联",
          desc: "多终端随时随地管理",
        },
        {
          key: 3,
          label: "四情大数据",
          desc: "全面的作物生长数据样本库",
        },
        {
          key: 4,
          label: "四情云监测",
          desc: "苗情监测、墒情灾情监测、病虫情监测",
        },
      ],

      deviceList: [
        {
          title: "智能高空虫情测报仪ST-GK101",
          desc: "诱集迁飞性害虫、照射高度500-2000m、虫水分离，智能型红外烘干APP、小程序、WEB三端查看。",
          key: 1,
          cover: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/programme/common/productimg2.png",
          path: "/special/GK101",
        },
        {
          title: "智能虫情测报仪ST-CQ101",
          desc: "象牙白搭配生命绿，外观简约自然，整机结构材质采用304不锈钢静电粉末喷涂处理，坚固耐用；拥有超过10年寿命，凸显超高性价比。",
          key: 2,
          cover: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/programme/common/productimg3.png",
          path: "/special/CQ101",
        },
        {
          title: "智能袍子捕获仪ST-BZ101",
          desc: "主要用于检测病害孢子存量及其扩散动态，为预测和预防病害流行、传染提供可靠数据。内含高倍显微镜，采用了二维码识别追溯技术、精度限位技术、自动智能化聚焦技术、4G/5G无线传输控制技术等高科技手段，增加精准定位功能，提高拍照清晰度。",
          key: 3,
          cover: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/programme/common/productimg1.png",
          path: "/special/BZ101",
        },
        {
          title: "太阳能墒情仪ST-SQ101",
          desc: "采用介电常数原理监测土壤水分含量以及温度状态进行动态观测，并可利用4G/5G技术，将所测数据上报到远程联物网平台。整体可以检测3层~5层或定制层数土壤温湿度状态；可以快速、全面的了解土壤墒情信息，科学地制定灌溉计划与抗旱救灾方案。",
          key: 4,
          cover: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/programme/common/productimg7.png",
          path: "/special/SQ101",
        },
        {
          title: "光伏自动气象测报站ST-X101",
          desc: "光伏自动气象测报站可监测空气温湿度、风速、风向、气压、雨量、光照强度、总辐射量等常规气象要素，可在无人值守的恶劣环境下全天候全自动运行，将所测数据通过4G/5G网络传输到物联网平台存储和分析。系统利用太阳能供电，无需另外接电。",
          key: 5,
          cover: "https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/programme/common/productimg6.png",
          path: "/special/x101",
        },
      ],
      deviceActive: 1,
    };
  },
  created() {},
  mounted() {
    this.addView()
    this.getBanner()
  },
  computed: {
    currentDevice() {
      return this.deviceList.filter((item) => item.key == this.deviceActive)[0];
    },
  },
  methods: {
    getBanner(){
      getBannerList('BCPGFC001Ban03').then(res=>{
        this.advantageList = res
      })
    },
    addView(){
      getNesViewApi({type:2,pid:"c635651a07b44b9a80171551ef016a47"})
    },
    clsFun() {
      // if (window.innerWidth > 800) {
      //   return "scale-page sticky-page";
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
.banner-box {
  overflow: hidden;
  // height: calc(100vh - 80px);
  width: 100%;
  // position: sticky;
  // top: 80px;
  .banner-content-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    padding-bottom: 100px;
    .title-span {
      font-size: 54px;
      font-weight: bold;
      margin-bottom: 30px;
    }
    .desc-span {
      font-size: 22px;
      font-weight: 400;
      line-height: 40px;
    }
    @media screen and (max-width: 1400px) {
      .title-span {
        font-size: 44px;
      }
      .desc-span {
        font-size: 18px;
      }
    }
    .banner-ul {
      display: flex;
      justify-content: space-between;
      width: 60%;
      margin-top: 90px;
      .banner-li {
        display: flex;
        flex-direction: column;
        align-items: center;
        .icon-span {
          width: 40px;
          height: 40px;
          font-size: 40px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .label-span {
          margin-top: 20px;
          font-size: 16px;
          font-weight: 400;
          color: #ffffff;
        }
      }
    }
  }
  .filter-div {
    position: absolute;
    width: 100%;
    height: 100px;
    z-index: 2;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(
      to bottom,
      rgba($color: #161616, $alpha: 0),
      rgba($color: #161616, $alpha: 1)
    );
  }
}
.f-label {
  padding: 0 69px;
  height: 68px;
  line-height: 60px;
  border: 2px solid $color-active;
  border-radius: 34px;
  font-size: 40px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
.m-device {
  display: none;
}
@media screen and (max-width: $mobile-width) {
  .banner-box {
    overflow: hidden;
    width: 100%;
    ::v-deep {
      .programme-banner img {
        width: 150%;
      }
    }
    .banner-content-div {
      text-align: center;
      padding-bottom: 5%;
      width: 86%;
      .title-span {
        font-size: 0.49rem;
        font-weight: bold;
        margin-bottom: 0.27rem;
      }
      .desc-span {
        font-size: 0.2rem;
        font-weight: 400;
        line-height: 0.36rem;
      }
      .banner-ul {
        margin-top: 10%;
        .banner-li {
          .icon-span {
            width: 0.36rem;
            height: 0.36rem;
            font-size: 0.36rem;
          }
          .label-span {
            margin-top: 0.18rem;
            font-size: 0.15rem;
          }
        }
      }
    }
    .filter-div {
      position: absolute;
      width: 100%;
      height: 100px;
      z-index: 2;
      bottom: 0;
      left: 0;
      background-image: linear-gradient(
        to bottom,
        rgba($color: #161616, $alpha: 0),
        rgba($color: #161616, $alpha: 1)
      );
    }
  }
  .f-label {
    padding: 0 0.63rem;
    height: 0.62rem;
    line-height: 0.55rem;
    border: 2px solid $color-active;
    border-radius: 0.31rem;
    font-size: 0.36rem;
    white-space: nowrap;
  }
  .w-device {
    display: none;
  }
  .m-device {
    display: block;
  }
}
</style>
