<!--
 * @Author: your name
 * @Date: 2021-11-25 13:45:02
 * @LastEditTime: 2021-12-23 16:50:07
 * @LastEditors: Please set LastEditors
 * @Description: 解决的问题
 * @FilePath: \new-website-1123\src\views\programme\pests\question.vue
-->
<template>
  <div class="pests-question">
    <p-title>
      为用户解决的
      <template v-slot:color>问题</template>
    </p-title>
    <div class="content-div">
      <p-icon v-for="item in dataList" :key="item.key" :icon="item.icon">
        <template v-slot:text1>{{ item.text1 }}</template>
        <template v-slot:text2>{{ item.text2 }}</template>
      </p-icon>
    </div>
  </div>
</template>

<script>
import pTitle from "../components/title.vue";
import pIcon from "../components/iconMsg.vue";
export default {
  name: "pests-question",
  components: {
    pTitle,
    pIcon,
  },
  data() {
    return {
      dataList: [
        {
          key: 1,
          icon: "iconbingchonghaideshishiyubaojiance",
          text1: "病虫害的实时",
          text2: "预报监测",
        },
        {
          key: 2,
          icon: "iconbingchonghaideyiliukuanianyubao",
          text1: "病虫害的遗留",
          text2: "跨年预报",
        },
        {
          key: 3,
          icon: "iconwufajingzhunshibiekunchongyubinghai",
          text1: "无法精准识别",
          text2: "昆虫与病害",
        },
        {
          key: 4,
          icon: "iconbingchonghaizhishibuzu",
          text1: "病虫害",
          text2: "知识不足",
        },
        {
          key: 5,
          icon: "iconbingchonghaijiancequyushujutongjinan",
          text1: "病虫害监测区域",
          text2: "数据统计难",
        },
        {
          key: 6,
          icon: "iconzhinengpanduangeleizuowugejieduanshifeiliang",
          text1: "智能判断各类作物",
          text2: "各阶段施肥量",
        },
        {
          key: 7,
          icon: "iconbaoshangbaofeijingzhunpensadayao",
          text1: "保墒保肥",
          text2: "精准喷洒打药",
        },
      ],
    };
  },
  created() {},
  methods: {},
};
</script>

<style lang='scss' scoped>
.pests-question {
  padding-top: 100px;
  width: 1200px;
  margin: auto;
  // height: 100vh;
  padding-bottom: 50px;
  .content-div {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    margin-top: 60px;
  }
}
@media screen and (max-height: 800px) {
  .house-config {
    padding-top: 80px;
    .content-div {
      margin-top: 60px;
    }
  }
}

@media screen and (max-width: $mobile-width) {
  .pests-question {
    padding-top: 60px;
    width: 100%;
    margin: auto;
    .content-div {
      margin: auto;
      margin-top: 0.55rem;
      width: 86%;
    }
  }
  .house-config {
    padding-top: 60px;
    .content-div {
      margin-top: 0.55rem;
      width: 86%;
    }
  }
}
</style>
